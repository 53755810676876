// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import Button, { ButtonGroup } from 'components/Button'
import supportedThemes from './themes/__supportedThemes'

import transDomain from './translations/index.translations'

import { Store } from 'Store'
import * as Form from 'components/Form'
import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'

import styles from './styles.module.scss'

type Props = WithFormProps | {
    values: {
        currentVehicleMileage: ?number,
        currentVehicleCondition: ?string,
        currentVehicleFinanceMethod: ?string,
        currentVehicleLeasePayment: ?string,
        currentVehicleLeasePaymentsLeft: ?string,
        currentVehicleFinancePayment: ?string,
        currentVehicleFinanceAmountOwing: ?string,
        storeLocationId: ?string,
        newVehicleMake: ?string,
        newVehicleModel: ?string,
        hotButtons: ?string,
        salesperson: ?string,
        timeline: ?string,
    }
}

function Step3 (props: Props) {
    const { theme } = props
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)

    const makes = state.campaign.models.map(model => model.make).filter((value, index, self) => self.indexOf(value) === index)

    if (makes.length === 1 && props.values.newVehicleMake !== makes[0]) {
        props.handleFieldChange('newVehicleMake', makes[0])
    }

    const models = React.useMemo(() => (
        state.campaign.models.filter(model => model.make === props.values.newVehicleMake)
    ), [props.values.newVehicleMake, state.campaign.models])

    if (state.campaign.locations.length === 1 && !props.values.storeLocationId) {
        props.handleFieldChange('storeLocationId', state.campaign.locations[0].id)
    }

    const salesReps = React.useMemo((): { [string]: string } => {
        const selectedStoreLocation = state.campaign.locations.find(location => location.id === props.values.storeLocationId)
        if (!selectedStoreLocation) return {}

        return {
            ...selectedStoreLocation.salesReps.reduce((salesReps, rep) => ({ [rep.name]: rep.name, ...salesReps }), {}),
            'none': t('field.salesperson.options.none.label')
        }
    }, [props.values.storeLocationId, state.campaign.locations, t])

    const hotButtons = React.useMemo(() => (props.values.hotButtons || []).map(i => i.toLowerCase()), [ props.values.hotButtons ])

    const handleBtnOnclick = value => {
        const values = hotButtons
        let a
        a = values.indexOf(value) !== -1
            ? values.filter(item => item !== value)
            : [...values, value].splice(-2)
        props.handleFieldChange('hotButtons', a)
    }

    const defaultModel = React.useMemo(() => {
        return models.find(model => model.name === props.values.newVehicleModel && model.year === props.values.newVehicleModelYear)
    }, [models, props.values.newVehicleModel, props.values.newVehicleModelYear])

    return (
        <>
            <Steps step={3} title={t('field.newVehicleMake.label')} description={t('description')} />
            {makes.length > 1
                ? <Form.Row>
                    <Form.Field>
                        <Form.Dropdown
                            // error={props.errors.newVehicleMake || null}
                            placeholder={t('field.newVehicleMake.placeholder')}
                            options={makes}
                            onChange={value => {
                                props.handleFieldChange('newVehicleMake', value)
                                props.handleFieldChange('newVehicleModelYear', '')
                                props.handleFieldChange('newVehicleModel', '')
                            }}
                            defaultValue={props.values.newVehicleMake}
                        />
                    </Form.Field>
                </Form.Row>
                : null
            }

            <Form.Row>
                <Form.Field
                    label={t('field.newVehicleModel.label')}
                >
                    <Form.ModelPickerDropdown
                        // error={props.errors.newVehicleModel || null}
                        placeholder={t('field.newVehicleModel.placeholder')}
                        models={models}
                        defaultModel={defaultModel}
                        onChange={value => {
                            props.handleFieldChange('newVehicleModelYear', value.year)
                            props.handleFieldChange('newVehicleModel', value.name)
                        }}
                    />
                </Form.Field>
            </Form.Row>

            <ButtonGroup className={styles.buttonGroup}>
                <Form.Row>
                    <Form.Field noBorder label={t('field.hotButtons.label')}>
                        <Form.RowColumns>
                            {[
                                'safety',
                                'performance',
                                'dependability',
                                'appearance',
                                'comfort',
                                'economy'
                            ].map((choise, key) => (
                                <Form.RowColumn key={key} className={styles.rowWrapper} size={0.3} padding={'1em 0.5em 0.1em 0.5em'}>
                                    <Button
                                        onClick={() => handleBtnOnclick(choise)}
                                        style={hotButtons.indexOf(choise) !== -1 ? theme.button : {
                                            border: `1px solid ${theme.button.background}`,
                                            color: theme.button.background
                                        }}>
                                        {t(`field.hotButtons.choice.${choise}`)}
                                    </Button>
                                </Form.RowColumn>
                            ))}
                            <input
                                tabIndex={-1}
                                type='text'
                                value={hotButtons.length === 2 ? `${hotButtons.length}` : ''}
                                onChange={() => null}
                                required={true}
                                style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    height: '100%',
                                    zIndex: 0,
                                    color: 'transparent',
                                    caretColor: 'transparent'
                                }}
                            />
                        </Form.RowColumns>
                    </Form.Field>
                </Form.Row>
            </ButtonGroup>

            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn>
                        <Form.Field label={t('field.timeline.label')}>
                            <Form.Dropdown
                                // error={props.errors.timeline || null}
                                placeholder={t('field.timeline.placeholder')}
                                options={['immediately', 'lessThanAWeek', 'aFewWeeks', 'aFewMonths']}
                                optionFormat={'field.timeline.options.%name%'}
                                trans={t}
                                onChange={value => props.handleFieldChange('timeline', value)}
                                defaultValue={props.values.timeline}
                            />
                        </Form.Field>
                    </Form.RowColumn>

                    <Form.RowColumn>
                        <Form.Field label={t('field.salesperson.label')}>
                            <Form.Dropdown
                                // error={props.errors.salesperson || null}
                                placeholder={t('field.salesperson.placeholder')}
                                options={salesReps}
                                onChange={value => props.handleFieldChange('salesperson', value)}
                                defaultValue={props.values.salesperson}
                            />
                        </Form.Field>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.Submit
                    isLoading={props.isLoading}
                    label={t('submit.label')}
                    style={{
                        background: theme.background,
                        color: theme.color,
                        marginTop: '0px',
                        marginBottom: '20px'
                    }}
                />
            </Form.Row>
        </>
    )
}

export default withForm()(withTheme(supportedThemes)(Step3))
