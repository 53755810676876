
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsf044df89936cc63714281aacfdd764a3en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'f044df89936cc63714281aacfdd764a3', flattenObject(nsf044df89936cc63714281aacfdd764a3en_US), true, true);
        
            import nsf044df89936cc63714281aacfdd764a3en from './en.yaml'
            i18n.addResourceBundle('en', 'f044df89936cc63714281aacfdd764a3', flattenObject(nsf044df89936cc63714281aacfdd764a3en), true, true);
        
        export default 'f044df89936cc63714281aacfdd764a3';
    