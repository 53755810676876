// @flow
import * as React from 'react'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'
import styles from './styles.module.scss'

function Sidebar (props) {
    const { theme, title, solidBackground } = props
    return (
        <div
            className={cn(styles.container, props.className, {
                [styles.solidBackground]: solidBackground
            })}
            style={{
                backgroundImage: `url(${theme.bgImage})`,
                '--background-color': theme.bgColor
            }}
                 >
            {title && <div className={styles.sidebarTtitle}>{title}</div>}
            {props.children}
        </div>
    )
}

export default withTheme(supportedThemes)(Sidebar)
