
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns7a8a9b8beddc5a0933e5391ca764e1ecen_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '7a8a9b8beddc5a0933e5391ca764e1ec', flattenObject(ns7a8a9b8beddc5a0933e5391ca764e1ecen_US), true, true);
        
            import ns7a8a9b8beddc5a0933e5391ca764e1ecen from './en.yaml'
            i18n.addResourceBundle('en', '7a8a9b8beddc5a0933e5391ca764e1ec', flattenObject(ns7a8a9b8beddc5a0933e5391ca764e1ecen), true, true);
        
        export default '7a8a9b8beddc5a0933e5391ca764e1ec';
    